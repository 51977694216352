/* App.css */

body {
  font-family: 'Silkscreen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  background-color: #121212; /* Dark background for a sleek appearance */
  color: #ffffff; /* White text for readability */
}

.container {
  max-width: 555px;
  margin: 0 auto;
  padding: 2rem;
  background-color: transparent; /* Card background color */
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

h1, p {
  text-align: center;
  margin-bottom: 1.5rem;
}

.form-control {
  background-color: #2b2b2b; /* Slightly lighter than body background */
  border: 1px solid #444; /* Border for inputs */
  color: #ffffff;
  padding: 0.75rem;
  border-radius: 8px;
}

.form-control:focus {
  outline: none;
  box-shadow: 0px 0px 5px #a9f4bc;
}

.btn-primary {
  background-color: #a9f4bc;
  color: #121212;
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  text-align: center;
  border-radius: 12px;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary:hover {
  background-color: #87cda0; /* Darker green for hover effect */
  box-shadow: 0px 4px 15px rgba(169, 244, 188, 0.5);
}

.text-center {
  margin-top: 1rem;
}

.dark-mode-toggle {
  position: sticky;
  top: 5rem;
  left: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #a9f4bc;
  transition: color 0.3s ease;
}

.dark-mode-toggle:hover {
  color: #ffffff;
}
