/* Floating Header Styles */
.floating-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1000;
}

body.dark-mode .floating-header {
  background-color: rgba(31, 31, 31, 0.9);
  color: #fff;
}

.logo img {
  width: 40px;
  height: auto;
}

.menu {
  position: relative;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: inherit;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  padding: 10px 0;
}

body.dark-mode .dropdown-menu {
  background-color: #2c2c2c;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #000;
  background-color: #a9f4bc;
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode .menu-item {
  color: #fff;
  background-color: #fa7814;
}

.menu-item:hover {
  background-color: #fa7814;
  color: #fff;
}

.menu-item .material-icons {
  margin-right: 5px;
}

/* Tooltip styling */
.menu-item[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 2000;
  opacity: 0.9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s;
}

/* Explicit light mode styling */
body.light-mode {
  background-color: #ffffff;
  color: #000000;
}

/* Dark mode styling to match the existing HTML */
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}
